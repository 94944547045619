import functions from './functions.js';
import calendar from  './calendar.js';
import Slider from "./Slider";

$(() => {
    details.init();
});

const details = {
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: '0,0,0', 
        numberOfPets: null,
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        if(! $('.page-type-details')[0]) {
            return;
        }

        let lastScroll = window.scrollY;
        
        $(window).scroll(e => {

            $('.link-to-top').toggleClass('show', lastScroll > 300);

            const offset = $('#booking').offset().top - $('#booking').outerHeight();

            $(window).scrollTop() > 200 
                ? $('.nav-top').addClass('visible') 
                : $('.nav-top').removeClass('visible');
            
            if($(window).scrollTop() > 550 && $(window).scrollTop() < offset) $('.nav-bottom').addClass('visible');
            if($(window).scrollTop() < 550) $('.nav-bottom').removeClass('visible');
            /*$(window).scrollTop() > 550 && $(window).scrollTop() < offset 
                ? $('.nav-bottom').addClass('visible') 
                : $('.nav-bottom').removeClass('visible');*/

            lastScroll = window.scrollY;

        });

        details.getParams();

        details.params.objectGroupId = $('#booking').attr('data-objectgroupid');
        details.params.unitId = $('#booking').attr('data-unitid');
        details.params.objectId = $('#booking').attr('data-objectid');

        //details.splitCalendarDates($('[name=dates]').val());

        details.calcErrorMessages = $('#booking').attr('data-calcErrorMessages') ? JSON.parse($('#booking').attr('data-calcErrorMessages')) : null;

        if($('[data-calendarconfig]')[0]) {
            details.calendarInit();
        } else {

        }

        if (details.params.guests && details.params.guests != '2,0,0' && details.params.guests != '2,0' && details.params.guests != '0,0,0') {
            $("[name='guests']").val(details.params.guests);
            $("[name='guests']").change();
        }
        else
        {
            details.params.guests = false;
            $('.select-guests-text').removeClass('d-none');

        }


        if (details.params.numberOfPets) {
            $("[name='numberOfPets']").val(details.params.numberOfPets);
            $("[name='numberOfPets']").change();
        }

        if(details.params.dateFrom && details.params.dateTo) {
            if($('[name="dates"]')[0]._flatpickr) {
                $('[name="dates"]')[0]._flatpickr.setDate([details.params.dateFrom, details.params.dateTo]);
            }
           details.callbackOnDatesSelected(details.params.dateFrom, details.params.dateTo);
        }

        details.getCalculation(null, '2,0,0');

        $('[name="dates"]').on('change', e => {
            e.preventDefault();
            const $target = $(e.target);
            details.splitCalendarDates($target.val());
            functions.setUrlData(details.params, true);
        });

        $('#booking').find('[name="guests"]').on('change', e => {
            let oldHash = window.location.hash;
            e.preventDefault();
            const $target = $(e.target);
            const $pets = $target.parent().find('[name="numberOfPets"]');
            details.params.guests = $target.val();
            details.params.numberOfPets = $pets.val();
            functions.setUrlData(details.params, true);
            let newHash = window.location.hash;

            if (oldHash == newHash) window.dispatchEvent(new HashChangeEvent("hashchange"))
            
        });

        $(".apply").on('click', e => {
            e.preventDefault();
            $(".simple-guests").dropdown('toggle');
        });

        $(".check-availability").on('click', e => {
            e.preventDefault();
            details.getParams();
            details.getCalculation();
        });

        $('.btn-clear-dates').on('click', function (e) {
            $('[name="dates"]')[0]._flatpickr.setDate([]);
            details.params.dateTo = details.params.dateFrom = null;
            functions.setUrlData(details.params, true);
            ['.total-old'].forEach((elem, i) => {
                let $elem = $(elem);
                if ($elem.length) {
                    let price = $elem.attr('data-price');
                    if (price && price !== '0,00' && price !== '0') {
                        let formatedPrice = '€ ' + price;
                        $elem.html(formatedPrice);
                        $elem.removeClass('d-none')
                    }
                    else {
                        $elem.html('');
                    }
                }
            })
            details.callbackOnDatesSelected(details.params.dateFrom, details.params.dateTo);
            details.getCalculation();
        });

        $(window).on('hashchange', e => {
            if ( ! details.params.objectId) {
                details.getParams();
                details.getCalculation();
            }
        });
        
        details.loadReviews();

        $('form#inquiry').on('submit', e => {
            e.preventDefault();

            $('.contact-btn').addClass('d-none');
            $('.contact-spinner').removeClass('d-none');
            $('.contact-spinner').addClass('d-flex');

            let params = {};
            $.each($(e.currentTarget).serializeArray(), function (i, field) {
                params[field.name] = field.value;
            });

            params['language'] = functions.getLang();

            params['requestId'] = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            grecaptcha.ready(function () {
                grecaptcha.execute('6LcUjF0gAAAAAGOKpLqFOqUQKsrys1Hi76OwosJb', { action: 'contact' }).then(function (token) {
                    params['gRecaptchaToken'] = token;
                    params = functions.getDataForUrl(params);
                    $.post('/services/inquiry/', params).then(response => {

                        $(".modal-body").html(response.data);

                        setTimeout(() => {
                            $('[data-bs-dismiss="modal"]').click();
                        }, 4000);

                        if (response.status == true) {
                            e.currentTarget.reset();
                        }
                    });
                });
            });
        });

        $('.unit-modal').on('click', e => {
            const $target = $(e.currentTarget);
            $('[id=' + $target.attr('data-tab') +']').tab('show');
        });

        let availabilitySlider = [];
        // availability-tab-58069

        $('[id^=availability]').on('shown.bs.tab', e => {
            $('#availability-slider-' + $(e.target).attr('data-unitid')).each((i, elem) => {
                availabilitySlider[i] = new Slider('#' + $(elem).attr('id'), {
                    rubberband: false,
                    loop: true,
                    arrows: true,
                    //slidesPerView: 4,
                    //spacing: 8,
                    slides: {
                        perView: 4,
                        spacing: 8,
                    },
                    breakpoints: {
                        '(max-width: 1000px)': {
                            slides: {
                                perView: 1,
                                spacing: 15,
                            },
                        },
                    },
                });
            });
        });

    },
    loadReviews() {
        $('.reviews-more').on('click', function (e) {
            let $button = $(e.currentTarget);
            let perPage = 3;
            let page = +$button.data('page') + 1;
            let total = Math.round(+$button.data('total') / perPage);

            $.post('/services/reviews/', { language : functions.getLang(), perPage : perPage, page: page, objectId: $button.data('objectid') }).then(response => {
                if (response.status == true) {
                    $button.data('page', page);
                    if (total <= page) {
                        $button.attr('disabled', 'disabled');
                    }
                    $(".reviews").append(response.html);
                }
            });
        });
    },
    calendarInit() {
        let config = JSON.parse($('[data-calendarconfig]').attr('data-calendarconfig'));

        let defaultDate = $('[data-calendarconfig][data-defaultdate]').attr('data-defaultdate');
        let minDate = $('[data-calendarconfig]').attr('data-mindate');
        /*if( ! details.params.dateFrom && defaultDate) {
            let dates = defaultDate.split(' - ');
            //details.params.dates = defaultDate;
            details.splitCalendarDates(defaultDate);
            defaultDate = dates[1] ? [dates[0], dates[1]] : (dates[0] ? dates[0] : []);
        }*/

        calendar.calendar.init(
            $('.datepicker-range-book'),
            {
                availabilityMask : config.availabilityMask,
                minStay : config.minStay,
                periods : config.periods,
                translations : config.translations,
                defaultDate: defaultDate ? defaultDate : [],
                minDate: minDate ? minDate : 'today',
                mode: 'range',
                inline: true,
                containerClass : 'details-booking-calendar mx-auto'
            },
            details.callbackOnDatesSelected
        );
    },
    callbackOnDatesSelected(from, to) {

        if ( to && ! to[1]) {
            $('.calendar-message').removeClass('d-none').text(details.getCalcErrorMessage({type : ! from ? 'selectDates' : 'selectDepartureDate'}));
            $('.btn-booking').addClass('d-none');
        }
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            details.params.dateFrom = aDates[0] ? aDates[0] : null;
            details.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getParams() {
        details.params = $.extend({}, details.params, functions.getUrlData(true));

        //details.splitCalendarDates(details.params.dates);
    },
    isBookable(data) {
        return data.isAvailable && data.calc.total && data.calc.status == 'Active';
    },
    getCalcErrorMessage(error) {
        if (error && details.calcErrorMessages && details.calcErrorMessages[error.type]) {
            return details.calcErrorMessages[error.type].replace('{$condition}', error.condition);
        }
        return details.calcErrorMessages ? details.calcErrorMessages.general : null;
    },
    getCalculation(unitId = null, guests = false) {

        //console.log(details.params);

        const dateFrom = details.params.dateFrom;
        const dateTo = details.params.dateTo;
        unitId = details.params.unitId;
        let calcGuests = guests || (details.params.guests == '0,0' || details.params.guests == '0,0,0' || ! details.params.guests ? '2,0' : details.params.guests);

        if(
            (details.params.objectId || unitId)
            && calcGuests != '0,0'
            && dateFrom 
            && dateTo 
            && details.params.objectGroupId
        ) {

            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + (unitId ? '?unitId=' + unitId : '?objectId=' + details.params.objectId)
                + '&guests=' + calcGuests
                + '&dateFrom=' + details.params.dateFrom
                + '&dateTo=' + details.params.dateTo
                + '&objectGroupId=' + details.params.objectGroupId
                + '&language=' + functions.getLang()
            )
            .done(response => {

                if(response.status) {
                    const data = response.data || [];

                    //console.log(data);

                    Object.entries(data).forEach(unit => {
                        const unitId = unit[0];
                        const unitData = unit[1];

                        const $unit = details.params.objectId ? $('.unit[data-unitid=' + unitId + ']') : $('body');

                        const $btnBooking = $('.btn-booking[data-unitid=' + unitId + ']');

                        if(details.isBookable(unitData)) {
                            const unitCalc = unitData.calc;
                            const calcItems = unitCalc.items;
                            let priceOld = 0.00;
                            let priceOldEUR = 0.00;
                            let discountTitle = null;
                            let currencySymbol = unitCalc.currencySymbol;
                            let currencySymbolBefore = unitCalc.currencySymbol != 'kn' ? currencySymbol : '';
                            let currencySymbolAfter = unitCalc.currencySymbol == 'kn' ? currencySymbol : '';

                            calcItems.forEach(item => {
                                // popust za djecu ne ulazi u prikaz discount cijene
                                if((item.itemType != 'discount' || item.itemDefinitionId == 129) && !item.optional && !item.onSpot) {
                                    priceOld += item.itemTotal;
                                    priceOldEUR += item.itemTotalEUR;
                                }
                                if(item.itemType == 'discount') {
                                    discountTitle = item.itemTitle;
                                    if(item.data && item.data.text) {
                                        discountTitle = item.data.text;
                                    }
                                }
                            });

                            const total = currencySymbolBefore + ' ' + functions.formatMoney(unitCalc.total, 0, '.', ',') + ' ' + currencySymbolAfter;
                            const totalEUR = functions.formatMoney(unitCalc.totalEUR, 0, '.', ',');

                            $unit.find('.calc-message-block').addClass('d-none');
                            $unit.find('.unit-price-container').removeClass('d-none');

                            
                
                            details.params.guests && details.params.guests != '0,0' ? $unit.find('.select-guests-text').addClass('d-none') : $unit.find('.select-guests-text').removeClass('d-none');

                            $unit.find('.date-from').text(functions.formatDate(dateFrom, 'dd.mm.yyyy.'));
                            $unit.find('.date-to').text(functions.formatDate(dateTo, 'dd.mm.yyyy.'));

                            $unit.find('.nights').text(unitCalc.nights);

                            $unit.find('.total-converted').text(totalEUR);
                            $unit.find('.total').text(total);
                            console.log(+priceOld > +unitCalc.total, +priceOld, +unitCalc.total);
                            if (+priceOld > +unitCalc.total)
                            {
                                $unit.find('.total-old').text(currencySymbolBefore + ' ' + functions.formatMoney(priceOld, 0, '.', ',') + ' ' + currencySymbolAfter);
                                $unit.find('.total-old').removeClass('d-none');
                            }
                            else
                            {
                                $unit.find('.total-old').addClass('d-none');
                            }

                            

                            $unit.find('.no-calculation').addClass('d-none');
                            $unit.find('.calculation').removeClass('d-none');

                            $unit.find('.from-label').addClass('d-none');
                            $unit.find('.total-label').removeClass('d-none');

                            $unit.find('.from-price').addClass('d-none');
                            $unit.find('.total').removeClass('d-none');

                            if (details.params.guests && details.params.guests != '0,0')
                            {
                                $btnBooking.removeClass('d-none')
                                $unit.find('.btn-select-dates').addClass('d-none');
                                $unit.find('.request-btn').removeClass('d-none');
                            }
                            else
                            {
                                $btnBooking.addClass('d-none');
                                $unit.find('.btn-select-dates').removeClass('d-none');
                                $unit.find('.request-btn').addClass('d-none');
                            }


                            if($btnBooking.attr('href')) {
                                $btnBooking.attr('href',
                                    $btnBooking.attr('href').split('?')[0] +
                                    '?unitId=' + unitId +
                                    '&dateFrom=' + details.params.dateFrom +
                                    '&dateTo=' + details.params.dateTo +
                                    '&guests=' + details.params.guests +
                                    (details.params.numberOfPets ? '&numberOfPets=' + details.params.numberOfPets : '')
                                );
                            }


                        } else {

                            $btnBooking.addClass('d-none');
                            //$('.unit-error-container').removeClass('d-none').html(details.getCalcErrorMessage(unitData.calc.message));
                            $unit.find('.calc-message-block').removeClass('d-none');
                            $unit.find('.calc-message').html(details.getCalcErrorMessage(unitData.calc.message || {type : unitData.message, condition : unitData.condition }));
                            $unit.find('.unit-price-container').addClass('d-none');

                            $unit.find('.no-calculation').removeClass('d-none');
                            $unit.find('.calculation').addClass('d-none');
                            $unit.find('.btn-select-dates').removeClass('d-none');

                            $unit.find('.total-label').addClass('d-none');
                            $unit.find('.from-label').removeClass('d-none');

                            ['.total-old'].forEach((elem, i) => {
                                let $elem = $(elem);
                                if ($elem.length) {
                                    let price = $elem.attr('data-price');
                                    if (price && price !== '0,00' && price !== '0') {
                                        let formatedPrice = '€ ' + price;
                                        $elem.html(formatedPrice);
                                        $elem.removeClass('d-none')
                                    }
                                    else {
                                        $elem.html('');
                                    }
                                }
                            })
                        }

                    });
                }

            }).fail(function (error) {
                console.log(error);
            });
        }

        else {
            ! details.params.guests ||  details.params.guests == '0,0' ? $('.select-guests-text').removeClass('d-none') : $('.select-guests-text').addClass('d-none');
            $('.btn-booking').addClass('d-none');
            $('.no-calculation').removeClass('d-none');
            $('.calculation').addClass('d-none');
            $('.request-btn').addClass('d-none');
            $('.btn-select-dates').removeClass('d-none');

            $('.calc-message').addClass('d-none');
            $('.unit-price-container').removeClass('d-none');

            $('.from-label').removeClass('d-none');
            $('.total-label').addClass('d-none');
            $('.from-price').removeClass('d-none');
            $('.total').addClass('d-none');

            $('.date-from').text('----');
            $('.date-to').text('----');
        }
    }
}