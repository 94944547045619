module.exports = data => {

    data.perPage = data.perPage || 10;
    data.total = data.total || 0;
    data.displayedPages = data.displayedPages || 5;
    data.currentPage = +data.currentPage || 1;

    const numberOfPages = Math.ceil(data.total / data.perPage);

    let start = Math.max(1, data.currentPage - Math.abs(Math.floor(data.displayedPages / 2)));
    let end = start + data.displayedPages;

    if ( end > numberOfPages ) {
        end = numberOfPages + 1;
        start = Math.max(1, end - data.displayedPages);
    }

    const pages = [];

    for ( let i = start; i < end; i++ ) {
        pages.push(i);
    }

    return `
        <ul class="pagination pagination-lg justify-content-center mt-4 mb-lg-0 mb-5">
            <!-- prev page -->
            ${numberOfPages && data.currentPage != 1 ? `
                <li class="page-item" data-prev>
                    <a class="page-link" href data-page="${data.currentPage - 1}">
                        <i class="la la-angle-left la-lg"></i>
                    </a>
                </li>
            ` : ``}
            <!-- first page -->
            ${numberOfPages && pages[0] != 1 ? `
                <li class="page-item" data-first>
                    <a class="page-link" href data-page="1">1</a>
                </li>
            ` : ``}
            <!-- pages -->
            ${pages.map(page => `
                <li class="page-item ${page == data.currentPage ? `active`: ``}" ${page < data.currentPage - 1 || page > data.currentPage + 1 ? `data-hidden`: ``}>
                    <a class="page-link" href data-page="${page}">${page}</a>
                </li>
            `).join("")}
            <!-- last page -->
            ${numberOfPages && pages[pages.length-1] != numberOfPages ? `
                <li class="page-item" data-last>
                    <a class="page-link" href data-page="${numberOfPages}">${numberOfPages}</a>
                </li>
            ` : ``}
            <!-- next page -->
            ${numberOfPages && data.currentPage != numberOfPages ? `
                <li class="page-item" data-next>
                    <a class="page-link" href data-page="${data.currentPage + 1}">
                        <i class="la la-angle-right la-lg"></i>
                    </a>
                </li>
            ` : ``}
        </ul>
    `;
};