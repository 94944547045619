import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
    } = window.wp.editor;
    
    const { 
        PanelBody,
        TextControl,
    } = window.wp.components;

    registerBlockType('custom-gutenberg/button-outline', {
        // built-in attributes
        title: 'Button Outline',
        description: 'Button Outline',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            text: {
                type: 'string',
                default: 'See more'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                link,
                text,
                classes,
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            let defaultClasess = 'button-outline btn btn-outline-dark';
            defaultClasess = classes && classes.includes('btn-outline-') ? 'button-outline btn ' + classes : defaultClasess;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };


            function onTextChange(text) {
                setAttributes({text: text});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Enter url: (ex: /en/villas/)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <br />
                        <p><strong>Text</strong></p>
                        <TextControl value={ text } onChange={ onTextChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <a className={ defaultClasess + (classes ? ' ' + classes : '') }>
                    <RichText 
                        key="editable" 
                        tagName="span"
                        placeholder="See more" 
                        value={ text }
                        onChange={ onTextChange } />
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                text,
                classes,
            } = attributes;

            let defaultClasess = 'button-outline btn btn-outline-dark';
            defaultClasess = classes && classes.includes('btn-outline-') ? 'button-outline btn ' + classes : defaultClasess;

            return (
                <a className={ defaultClasess + (classes ? ' ' + classes : '') } href={ link }>
                    { text }
                </a>
            );
        },
    });
}