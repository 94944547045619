import functions from "./functions.js";

const review = {
    init() {
        $('.newsletter-form').on('submit', function(e) {
            let $elem = this;
            e.preventDefault();
            e.stopPropagation();
    
           /* $($elem).find('.contact-btn').addClass('d-none');
            $($elem).find('.contact-spinner').removeClass('d-none');
            $($elem).find('.btn-newsletter').addClass('d-none');*/
    
            window.grecaptcha && grecaptcha.ready(function() {
                $($elem).find('.contact-btn').addClass('d-none');
                $($elem).find('.contact-spinner').removeClass('d-none');
                $($elem).find('.btn-newsletter').addClass('d-none');
                grecaptcha.execute('6LcUjF0gAAAAAGOKpLqFOqUQKsrys1Hi76OwosJb', {action: 'newsletter'}).then(function(token) {
                    let params = {};
                    $.each($($elem).serializeArray(), function (i, field) {
                        params[field.name] = field.value;
                    });
    
                    //params['newsletter'] = false;
                    //params['email'] = $form[0].email.value;
                    params['language'] = functions.getLang();
        
                    $.post('/services/mailchimp/', params).then(response => {
                        $($elem).find('.contact-btn').removeClass('d-none');
                        $($elem).find('.contact-spinner').addClass('d-none');
                        

                        $($elem).find(".response-output").removeClass('d-none').html(response.data);
    
                        setTimeout(() => { 
                            $($elem).find(".response-output").addClass('d-none').html('');
                            $($elem).find('.btn-newsletter').removeClass('d-none');
                        }, 5000);
    
                        // if (response.status == true) {
                            $elem.reset();
                        // }
                        
                    });
                });
            });
        })
    
    }
}



$(() => {
    review.init();
});