import functions from "./functions.js";
let globalEventsInitiated = false;
let id = 0;
const instances = {};

const SimpleGuestsDefaults = {
	maxGuests: 100,
	maxChildren: 100,
	maxInfants: 100,
	maxPets: 100,
	childrenAge: 14,
	infantsAge: 2,
	adultMandatory: true,
	noGuestLabel: 'Enter number of guests',
	adultsLabel: 'Adults',
	childrenLabel: 'Children up to $y years',
	infantsLabel: 'Infants up to $y years',
	petsLabel: 'Pets',
	guestsLabel: 'guests',
	btnValueTpl: (adults, children, infants, pets) => {
		
		return (adults + children == 0) ? SimpleGuestsDefaults.noGuestLabel : (`${adults + children} ${SimpleGuestsDefaults.guestsLabel}${infants ? ', ' + infants + ' infants' : ''}` + (pets && pets > 0 ? ', ' + pets + ' ' + SimpleGuestsDefaults.petsLabel.toLowerCase() : ''));
	},
	// to do some logic on closing dropdown, return false to prevent change event on input
	onClose: () => {
		return true;
	}
};

class SimpleGuests {

	constructor(elem, options = {}) {
		this.id = ++id;
		instances[this.id] = this;

	  	this.elem = elem;
	  	this.$elem = $(elem);
		this.$parent = $(elem).closest('.dropdown, .form-group').attr('data-igid', this.id);
		this.$controls = this.$parent.find('.simple-guests-controls');
		this.$input = this.$parent.find('[name="guests"]');
		this.$inputPets = this.$parent.find('[name="numberOfPets"]');
		this.$value = this.$elem.find('.simple-guests-value');

		this.isDropdown = this.$parent.hasClass('dropdown');

		this.setOptions(options);
		this.buildControls();
		this.setValue(this.$input[0].value);
		this.setEvents();
	}

	setOptions(options) {
		if ( !functions.isObject(options) ) {
			options = {};
		}

		// set properted from options or dom attributes
		this.maxGuests = +(options.maxGuests || this.$elem.attr('data-maxGuests') || SimpleGuestsDefaults.maxGuests);
		this.maxChildren = +(options.maxChildren || this.$elem.attr('data-maxChildren') || SimpleGuestsDefaults.maxChildren);
		this.maxInfants = +(options.maxInfants || this.$elem.attr('data-maxInfants') || SimpleGuestsDefaults.maxInfants);
		this.maxPets = +(SimpleGuestsDefaults.maxPets);

		if (this.$elem.attr('data-maxpets')) this.maxPets = +this.$elem.attr('data-maxpets');

		this.childrenAge = +(options.childrenAge || this.$elem.attr('data-childrenAge') || SimpleGuestsDefaults.childrenAge);
		this.infantsAge = +(options.infantsAge || this.$elem.attr('data-infantsAge') || SimpleGuestsDefaults.infantsAge);
		this.adultMandatory = Boolean(options.adultMandatory || this.$elem.attr('data-adultMandatory') || SimpleGuestsDefaults.adultMandatory);
		this.adultMandatory = this.$elem.attr('data-adultMandatory') ? this.$elem.attr('data-adultMandatory') === 'true' : this.adultMandatory

		// set max childs
		let maxChilds = this.maxGuests - (this.adultMandatory ? 1 : 0);
		this.maxChildren = this.maxChildren > maxChilds ? maxChilds : this.maxChildren;
		this.maxInfants = this.maxInfants > maxChilds ? maxChilds : this.maxInfants;

		this.onClose = options.onClose || SimpleGuestsDefaults.onClose;
	}

	buildControls() {
		this.$adults = this.buildControl('adults', SimpleGuestsDefaults.adultsLabel);
		this.$children = this.buildControl('children', SimpleGuestsDefaults.childrenLabel, SimpleGuestsDefaults.childrenAge);
		this.$infants = this.maxInfants ? this.buildControl('infants', SimpleGuestsDefaults.infantsLabel, SimpleGuestsDefaults.infantsAge) : null;
		this.$pets = this.maxPets ? this.buildControl('pets', SimpleGuestsDefaults.petsLabel) : null;
		this.$controls.append(this.$adults, this.$children, this.$pets);
	}

	buildControl(name, label, years) {
		return $(`
			<div class="row align-items-center simple-guests-control" data-control="${name}">
				<div class="col" data-label>${label.replace('$y', years)}</div>
				<div class="col-auto input-group border-0 align-items-center">
					<div class="input-group-prepend">
						<button tabindex="-1" class="btn btn-light" data-update="${name}" data-direction="-1" type="button">
							<i class="la la-minus"></i>
						</button>
					</div>
					<div data-value></div>
					<div class="input-group-append">
						<button tabindex="-1" class="btn btn-light" data-update="${name}" data-direction="1" type="button">
							<i class="la la-plus"></i>
						</button>
					</div>
				</div>
			</div>
		`);
	}

	getControlMinMax(name) {
		let min = (name == 'adults' && this.adultMandatory) ? 2 : 0;
		let max = this['max' + name[0].toUpperCase() + name.slice(1)] || this.maxGuests;
		let computedMax = this.maxGuests - (this.adults || 0) - (this.children || 0) - (this.infants || 0);
		computedMax = this[name] + computedMax;
		max = max > computedMax ? computedMax : max;
		if (name == 'pets') max = this['max' + name[0].toUpperCase() + name.slice(1)];
		return [min, max];
	}

	setValue(value) {
		value = value.split(',');
		let adults = value[0];
		let childs = value.slice(2).reduce((count, age) => {
			count[age] = (count[age] || 0) + 1;
			return count;
		}, {});

		this.setControlValue('adults', adults);
		this.setControlValue('children', childs[this.childrenAge]);
		this.$infants && this.setControlValue('infants', childs[this.infantsAge]);
		this.$pets && this.setControlValue('pets', this.$inputPets[0].value);
		this.setInputValue();
	}

	setControlValue(name, value = 0) {
		const $control = this['$' + name];
		const [min, max] = this.getControlMinMax(name);
		value = +value;
		if ( value < min ) {
			value = min;
		}
		else if ( value > max ) {
			value = max;
		}

		this[name] = value;
		if ($control)
		{
			$control.find('[data-value]').html(value);
			this.checkUpdateBtns('adults');
			this.checkUpdateBtns('children');
			this.$infants && this.checkUpdateBtns('infants');
			this.$pets && this.checkUpdateBtns('pets');
		}
	}

	checkUpdateBtns(name) {
		const value = this[name];
		const [min, max] = this.getControlMinMax(name);

		this.$controls.find(`[data-update="${name}"][data-direction="-1"]`).prop('disabled', value == min);
		this.$controls.find(`[data-update="${name}"][data-direction="1"]`).prop('disabled', value == max);
	}

	setEvents() {
		// react on "outer input set value"
		this.$input.on('change', () => {
			this.setValue(this.$input[0].value);
		});
		this.$inputPets.on('change', () => {
			this.setControlValue('pets', this.$inputPets[0].value);
			this.setBtnValue();
		});

		this.$controls.on('click', '[data-update]', e => {
			const $btn = $(e.currentTarget);
			const name = $btn.attr('data-update');
			const value = this[name];
			const direction = +$btn.attr('data-direction');
			this.setControlValue(name, value + direction);
			name != 'pets' ? this.setInputValue() : this.$inputPets.val(value + direction);
		});

		if ( !globalEventsInitiated ) {
			globalEventsInitiated = true;
			$(document).on('click', '.simple-guests-controls.dropdown-menu', e => {
				e.stopPropagation();
			});
			// ke kaco - doesnt work as jquery.on ...
			document.addEventListener('hide.bs.dropdown', e => {
				const $btn = $(e.target);
				const $dropdown = $btn.closest('.dropdown');
				const igid = $dropdown.attr('data-igid');

				if ( $btn.hasClass('show') && igid ) {
					instances[igid].close();
				}
			});
		}
	}

	setInputValue() {
		const value = [
			this.adults,
			this.adults ? this.children + (this.$infants ? this.infants : 0) : 0,
			...Array(this.children).fill(this.adults ? this.childrenAge : ''),
			...(this.$infants ? Array(this.infants).fill(this.infantsAge) : [])
		];

		this.$input[0].value = value.join(',');
		this.$value.length && this.setBtnValue();
		!this.isDropdown && this.$input.trigger('change');
	}

	setBtnValue() {
		this.$value.html(SimpleGuestsDefaults.btnValueTpl(this.adults, this.children, this.infants, this.$inputPets[0].value));
	}
	// for public use
	getValue() {
		return this.$input[0].value;
	}
	// only if dropdown version
	close() {
		typeof this.onClose === 'function' && this.onClose() && this.$input.trigger('change');
	}

}

export {
	SimpleGuests,
	SimpleGuestsDefaults
};
