import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InspectorControls,
        MediaUpload,
        InnerBlocks
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        Button
    } = window.wp.components;

    registerBlockType('custom-gutenberg/background', {
        // built-in attributes
        title: 'Background',
        description: 'Background',
        icon: '',
        category: 'custom-containers',
        example: {},

        // custom attributes
        attributes: {
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                image,
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onSelectImage(image) {
                setAttributes({image: image.sizes.full.url});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <div>
                                    { image && 
                                        <div>
                                            <figure>
                                                <img src={image} />
                                            </figure>
                                            <div>
                                                <Button onClick={() => setAttributes({ image: '' })} className="button">Remove</Button>
                                            </div>
                                            <br></br>
                                        </div>
                                
                                    }
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                                </div>
                            ) }  />
                    </PanelBody>
                </InspectorControls>
                ,

                <div className={ (classes ? ' ' + classes : '') } style={ { backgroundImage: `url(${image})` } } >
                    <InnerBlocks />
                </div>
                
            ]
            );
        },

        save({ attributes }) {

            const {
                image,
                classes
            } = attributes;

            return (
                <div className={ (image ? 'lozad' : '') + (classes ? ' ' + classes : '') } data-background-image={ image }>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}