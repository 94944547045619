const getClassNames = className => {
    return className.split(' ').filter(function(value, index, self) { 
        return self.indexOf(value) === index;
    }).join(' ');
};

const slugify = str => {
    str = str.trim().toLowerCase();
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
             .replace(/\s+/g, '-') // replace spaces with hyphens
             .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
};

module.exports = {
    getClassNames: getClassNames,
    slugify: slugify
};