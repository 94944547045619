$(function () {
    let $tracking = $('.gatracking');

    if ($tracking.length)
    {
        let trackingData = JSON.parse($('[data-gatracking]').attr('data-gatracking'));
    
        if (trackingData)
        {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push(trackingData);  
        }
    }
});