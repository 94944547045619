import functions from "./functions.js";
import tplLoader from "./tpl-loader.js";
import pagination from "./pagination.js";
import Modal from 'bootstrap/js/dist/modal';
import MarkerClusterer from "@google/markerclustererplus";
import Slider from "./Slider.js";

const list = {
    allowInfoWindow: true,
    $form: null,
    form: null,
    $moreFiltersBtns: null,
    $moreFiltersBtnsIcons: null,
    moreFilters: false,
    $moreFiltersContainer: null,
    $shownItems: null,
    $total: null,
    $body: {},
    $list: null,
    list: null,
    $listWrap: null,
    $map: null,
    map: null,
    infoWindow: null,
    cluster: null,
    lastEvent: null,
    // form params
    params: {},
    defaults: {
        dateFrom: '',
        dateTo: '',
        ms: '',
        objectTypeId: '',
        guests: '2,0,0',
        numberOfPets: '',
        personsInObject: '',
        sortBy: 0,
        _page: 1,
        perPage: 10,
        rooms: '',
        bathrooms: '',
        themeId: '',
        tagCategoryId: '',
        distanceBeach: '',
        distanceCenter: '',
        objectId: '',
        parking: '',
        pool: '',
        pets: '',
        grill: '',
        ac: '',
        sattv: '',
        internet: '',
        disabledPersons: '',
        washingMachine: '',
        heatedPool: '',
        hydromassageBathtub: '',
        sauna: '',
        coordinates: '',
        language: functions.getLang(),
        objectName: '',
        personsInObject: 0
    },
    mainFields: ['dateFrom', 'dateTo', 'ms', 'objectTypeId', 'guests', 'personsInObject', 'sortBy', '_page', 'dates'],
    // set by hash change to prevent change event on form fields
    pauseChange: false,
    // hashchange and form fields change are delayed
    changeTimeout: null,
    loading: false,
    // ajax load id
    loadId: 0,
    total: null,
    // for non ajax form to reset page on normal submit
    paginated: false,
    disabledFields: {},
    locations: null,

    setLoadingMode() {
        if ( !list.loading ) {
            list.loading = true;
            if (list.$body.length) {
                list.$body.addClass('loading');
                list.$listWrap.prepend(tplLoader).scrollTop(0);
                list.$listWrap.scrollTop(0); // mobile
                list.$list.scrollTop(0);
                $('.list').scrollTop(0);
                $('html').scrollTop(0);
                list.infoWindow && list.infoWindow.close();
               
            } else {
                list.$form
                    .find('.form-submit').prop('disabled', true)
                    .find('i').addClass('fa-spinner fa-spin fa-lg');
            }
        }
    },
    splitDates(params) {
        if(params.dates)
        {
            let val = params.dates.split(' - ');
            params.dateFrom  = val[0];
            params.dateTo    = val[1];
            params.dates = null;
        }
        else
        {
            return;
        }
        
    },
    unsetLoadingMode() {
        if ( list.loading ) {
            list.loading = false;
            list.$body.removeClass('loading').find('.list-loader').remove();
        }
    },
    toggleFilters(toggle) {
        list.moreFilters = toggle === undefined ? !list.moreFilters : toggle;
        list.$moreFiltersBtns.toggleClass('active', list.moreFilters);
        list.$moreFiltersBtnsIcons.toggleClass('fa-chevron-up', list.moreFilters);
    },
    toggleMobileForm(toggle) {
        if ( toggle ) {
            list.$body.addClass('modal-active');
        } else {
            list.$body.removeClass('modal-active');
            list.toggleFilters(false);
        }
    },
    getParams() {
        list.params = $.extend({}, list.defaults, functions.getUrlData(true));
        if(list.params.objectName) list.params.objectName = list.params.objectName.split(',')[0];
        if(list.params.rooms) list.params.rooms = list.params.rooms.split(',')[0];
        if(list.params.bathrooms) list.params.bathrooms = list.params.bathrooms.split(',')[0];
        list.setFiltersCount();
    },
    setDisabledFields() {
        let disabledFields = list.$form.attr('data-disabledFields');
        if ( disabledFields ) {
            disabledFields = JSON.parse(disabledFields);
            $.map(disabledFields, (value, name) => {
                if ( list.form[name] ) {
                    list.disabledFields[name] = true;
                    list.defaults[name] = value;
                    list.form[name].disabled = true;
                    if ( name == 'dateFrom' || name == 'dateTo' ) {
                        $(list.form[name]).next().prop('disabled', true);
                    }
                }
            });
        }
    },
    onChange(e) {

        clearTimeout(list.changeTimeout);
        
        if ( list.pauseChange ) {
            return;
        }
        // if changed field is date range and not have dateFrom & dateTo prevent url push
        if ( e && e.target.name == 'dates' ) {
            let val = e.target.value.split(' - ');
            if ( val.length != 2 && val[0] != '') {
                return;
            }  
        }

        if ( e && e.target.name == 'ms') {
            if(list.params.ms == '' && list.params.coordinates != '') {
                list.params.coordinates = ''; 
            }
        }

        if( e && list.mainFields.indexOf(e.target.name) === -1)
        {
            return;
        }
        // not advanced (ajax) search
        if ( !list.$body.length ) {
            return;
        }

        list.allowInfoWindow = false;

        list.changeTimeout = setTimeout(() => {
            functions.setUrlData(list.getFormData(), list.$body.length, list.defaults);
        }, 300);
    },
    getFormData() {
        let formData = $.extend(
            functions.getFormData(list.$form), {
                coordinates: list.params.coordinates,
                _page: list.paginated ? list.params._page : 1,
                sortBy: list.paginated ? list.params.sortBy : 0
            }
        );
        list.paginated = false;
        // do not send same values as defaults
        $.map(formData, (val, key) => {
            if ( list.defaults[key] == val ) {
                delete formData[key];
            }
        });
        return formData;
    },
    init() {
        list.$form = $('.search-form');
        if( !list.$form.length )
        {
            return;
        }
        list.$body = $('.body-listing');
        list.form = list.$form[0]

        list.$moreFiltersBtns = list.$form.find('.more-filters');
        list.$moreFiltersBtnsIcons = list.$moreFiltersBtns.find('i');
        list.$filtersCount = list.$moreFiltersBtns.find('.filters-count');
        list.$moreFiltersContainer = list.$form.find('.more-filters-container');

        // set default params
        list.defaultParams = JSON.parse(list.$form.attr('data-params'));
        $.map(list.defaultParams, (value, key) => {
            list.defaults[key] = value;
        });

        list.setDisabledFields();
        list.getParams();


        functions.setFormData(list.form, list.params);
        if (list.params.dates) 
        {
            let $datesModal = $('.modal-dates');

            let dates = list.params.dates.split(' - ');
            if(dates[0] && dates[1] && $datesModal[0] && $datesModal[0]._flatpickr) 
            {
                $datesModal[0]._flatpickr.setDate([dates[0],dates[1]]);
            }    
        }


        list.$body.length && list.find();

        // register form fields changes
        list.$form.on('change', list.onChange);

        list.$form.on('submit', e => {
            e.preventDefault();
            if ( list.$body.length ) {
                list.onChange();
                list.toggleMobileForm(false);
                //list.closeModal();
            } else {
                // non ajax form
                let data = $.param(functions.cleanParams(functions.getFormData(this.$form), this.defaults)),
                toHash = list.$form.hasClass('form-hash');
                let href = location.protocol + '//' + location.host + list.$form.attr('action');
                if ( data ) {
                    href += (href.indexOf('?') === -1 ? (toHash ? '#' : '?') : '&');
                }
                href += data;
                list.setLoadingMode();
                list.toggleFilters(false);
                location.href = href;
            }
        });
        // register hashchange which trigger load
        $(window).on('hashchange', () => {
            list.pauseChange = true;
            clearTimeout(list.changeTimeout);
            list.changeTimeout = setTimeout(() => {
                list.getParams();
                functions.setFormData(list.form, list.params);
                list.markers && list.clearMarkers();
                list.find();
                list.params.coordinates && $(list.form.ms).next().find('.select2-selection__placeholder').text("Area on map");
                list.pauseChange = false;
            }, 100);
        });


        // register paginate        
        $(document).on('click', '.pagination a[data-page]', e => {
            e.preventDefault();
            list.paginate(+$(e.currentTarget).attr('data-page'));
        });

        // more filters actions
        // list.$moreFiltersBtns.on('click', list.toggleFilters);
        // list.$moreFiltersContainer.find('.apply-filters').on('click', () => {
        //     list.toggleFilters(false);
        //     if ( list.$body.length ) {
        //         list.onChange();
        //     } else {
        //         list.$form.submit();
        //     }
        // });
        list.$moreFiltersContainer && list.$moreFiltersContainer.find('.clear-filters').on('click', e => {
            let changed = false;
            let formData = {}
            $.map(list.params, (value, key) => {
                if ( list.mainFields.indexOf(key) === -1 ) {
                    if ( list.params[key] && list.params[key] != list.defaults[key] ) {
                        changed = true;
                    }
                    formData[key] = list.defaults[key];
                }
            });
            functions.setFormData(list.form, formData);
            if ( changed ) {
                if ( !e.target.classList.contains('clear-filters-mobile') ) {
                    list.onChange();
                }
                if ( !list.$body.length ) {
                    list.$form.submit();
                }
            }
        });

        // show list item on map
        if ( list.$list ) {
            list.$list.on('mouseenter', '.show-on-map', e => {
                e.preventDefault();
                const $this = $(e.currentTarget);
                const publicId = +$this.attr('data-publicId');
                const marker = list.markers.filter(marker => marker.publicId === publicId)[0];
                list.suppressMapEvents = true;
                // list.map.setCenter(marker.position);
                // list.map.setZoom(15);                
                google.maps.event.trigger(marker, 'click');
            });
        }

        $('#fullscreen').on('click tap vclick', function() {
            $('.list').css('opacity', 0);
            $('.list').addClass('hide-list-list');
            $('.map-and-filters').addClass('d-none');
            $('.map-close-btn').removeClass('d-none');
            $('.list-map').addClass('show-map-list');
            $('.list-wrap').addClass('list-wrap-overflow');
            $('.filters-bar-map').removeClass('d-none');
        });
        
        $('#close-map').on('click tap vclick', function() {
            $('.list').css('opacity', 1);
            $('.list').removeClass('hide-list-list');
            $('.map-and-filters').removeClass('d-none');
            $('.map-close-btn').addClass('d-none');
            $('.list-map').removeClass('show-map-list');
            $('.list-wrap').removeClass('list-wrap-overflow');
        });

        if(functions.isMobile()) {
            $('#filtersModal').on('show.bs.modal', e => {
                $('.hide-list-list').css('opacity', 1);
                $('.list-wrap').css('z-index', 10000);
            });
            $('#filtersModal').find('[data-bs-dismiss="modal"]').on('click', e => {
                $('.list-wrap').css('z-index', 0);
                $('.hide-list-list').css('opacity', 0);

                $('.list-items').css('opacity', 0);
                $('.list-wrap').find('.filters-bar').css('opacity', 0);
                $('.list-title').css('opacity', 0);
            });
            $('#filtersModal').on('hidden.bs.modal', e => {
                $('.list-items').css('opacity', 1);
                $('.list-wrap').find('.filters-bar').css('opacity', 1);
                $('.list-title').css('opacity', 1);
            });
        }

    },
    
    setFiltersCount() {
        let filtersCount = 0;
        $.map(list.params, (val, name) => {
            list.mainFields.indexOf(name) === -1 && (val !== list.defaults[name] || list.disabledFields[name]) && (filtersCount++);
        });
        list.$filtersCount.html(filtersCount || '');
    },
    loadMap() {
        if ( $('.list-map').length ) {
            window.loadGoogleMaps().then(list.initMap);
        }
    },
    initMap() {
        list.$map = $('.list-map');
        list.map = new google.maps.Map(list.$map[0], {
            zoom: 10,
            center: { lat: 45.12740978317192, lng: 13.902139984375038 },
            gestureHandling: 'greedy',
            mapTypeControl: false,
            styles: [{
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [
                    { visibility: 'off' }
                ]
            }]
        });

        if(functions.isMobile() && ! list.$map.hasClass('filters-bar')) {
            const $filtersBarMap = $('.filters-bar-map');
            let html = $filtersBarMap.html();
            $filtersBarMap.html('');
            list.$map.append('<div class="filters-bar">' + html + '</div>');
        }

        $('#close-map').on('click tap vclick', function() {
            $('.list').css('opacity', 1);
            $('.list').removeClass('hide-list-list');
            $('.map-and-filters').removeClass('d-none');
            $('.map-close-btn').addClass('d-none');
            $('.list-map').removeClass('show-map-list');
            $('.list-wrap').removeClass('list-wrap-overflow');
        });

        // only trigger mapfind when zoomout or dragend, zoomin we already have data
        list.map.addListener('zoom_changed', () => {
            if ( list.suppressMapEvents ) {
                return;
            }
            list.cluster && list.cluster.clearMarkers();
            list.lastEvent = 'zoom';
        });
        list.map.addListener('dragend', () => {
            if ( list.suppressMapEvents ) {
                return;
            }
            list.cluster && list.cluster.clearMarkers();
            list.lastEvent = 'dragend';
        });
        list.map.addListener('bounds_changed', () => {
            if ( list.suppressMapEvents ) {
                setTimeout(() => {
                    list.suppressMapEvents = false;
                });
            }
        });
        list.map.addListener('idle', () => {
            if ( list.suppressMapEvents ) {
                return;
            }
            if ( !list.lastEvent ) {
                return;
            }

            const bounds = list.map.getBounds();
            const sw = bounds.getSouthWest();
            const nw = bounds.getNorthEast();
            list.params.coordinates = sw.lat() + ',' + nw.lat() + ',' + sw.lng() + ',' + nw.lng();
            list.params._page = 1;
            if(list.params.ms) {
                list.params.ms = '';
            }
            functions.setUrlData(list.params, true, list.defaults);
        });
    
        // init info window
        list.infoWindow = new google.maps.InfoWindow({
            maxWidth: 300
        });
        list.infoWindow.addListener('domready', () => {
            // init infoWindow slider
        });
    },
    mapFind(ajaxId) {
        let params = Object.assign({}, list.params);
        if ( list.lastEvent ) {
            const bounds = list.map.getBounds();
            const sw = bounds.getSouthWest();
            const nw = bounds.getNorthEast();
            params.coordinates = list.params.coordinates = sw.lat() + ',' + nw.lat() + ',' + sw.lng() + ',' + nw.lng();
        } else {
            if ( !params.ms ) {
                //params.ms = '8';
            }
        }
        this.splitDates(params);
        $.get('/services/map', params).then(response => {
            if ( ajaxId !== list.loadId ) {
                return;
            }
            list.locations = JSON.parse(response);
            list.setMarkers();
        });
    },
    setMarkers() {
        if ( !list.map ) {
            setTimeout(list.setMarkers, 50);
            return;
        }
        // init each marker
        list.markers = list.locations.map(location => {
            location.lat = +location.lat;
            location.lng = +location.lng;
            const marker = new google.maps.Marker({
                position: location,
                map: list.map,
                icon: {
                    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                    scale: 0.6,
                    fillColor: "#a6467e",
                    fillOpacity: 0.85,
                    strokeWeight: 0
                }
            });
            marker.publicId = location.publicId;
            marker.addListener('click', () => {
                const params = {
                    publicId: marker.publicId,
                    dateFrom: list.params.dateFrom,
                    dateTo: list.params.dateTo,
                    guests: list.params.guests,
                    language: functions.getLang(),
                    template: 'map'
                };
                if (list.allowInfoWindow) {
                    $.get('/services/objectDetails', params).then(response => {
                        let res = JSON.parse(response);
                        if ( response ) {
                            list.infoWindow.setContent(res.content);
                            list.infoWindow.setPosition({lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});
                            list.infoWindow.open(list.map);
                        }
                    });
                }
            });
            return marker;
        });
        // init cluster
        if ( !list.cluster ) {
            list.cluster = new MarkerClusterer(list.map, [], {
                minimumClusterSize: 3,
                maxZoom: 14,
                zoomOnClick: false,
                styles: [{
                    url: '/wp-content/uploads/cluster.png',
                    textSize: 14,
                    height: 40,
                    width: 40
                }]
            });
            // force cluster full zoom
            list.cluster.addListener('click', function(cluster) {
                list.map.setCenter(cluster.center_);
                list.map.fitBounds(cluster.getBounds());
                // list.map.setZoom(15);
            });
        }

        list.cluster.clearMarkers();
        list.cluster.addMarkers(list.markers);
        if ( !list.lastEvent ) {
            list.suppressMapEvents = true;
            if ( list.markers.length === 1 ) {
                list.map.setCenter(list.markers[0].position);
                list.map.setZoom(15);
            }
            else if ( list.markers.length ) {
                const bounds = new google.maps.LatLngBounds();
                for (var i = 0; i < list.markers.length; i++) {
                    bounds.extend({lat: list.markers[i].getPosition().lat(), lng: list.markers[i].getPosition().lng()})
                }
                list.map.fitBounds(bounds);
            }
            else if ( list.params.coordinates ) {
                let coordinates = list.params.coordinates.split(',');
                const bounds = new google.maps.LatLngBounds();
                bounds.extend({ lat: +coordinates[0], lng: +coordinates[2] });
                bounds.extend({ lat: +coordinates[1], lng: +coordinates[3] });
                list.map.fitBounds(bounds);
            }
            return;
        }
        list.lastEvent = null;
    },
    paginate(page) {
        if ( page != list.params._page ) {
            list.params._page = page;
        }
        if ( list.$body.length ) {
            functions.setUrlData(list.params, list.$body.length, list.defaults);
        } else {
            list.paginated = true;
            list.$form.submit();
        }
    },
    find() {
        // init list
        if ( !list.$list ) {
            list.$list = $('.list-items').length ? $('.list-items') : $('.list');
            list.list = list.$list[0];
            list.$listWrap = list.$list.closest('.list-wrap');
            list.unsetLoadingMode();
        }
        list.setLoadingMode();
        let ajaxId = ++list.loadId;
        list.mapFind(ajaxId);
        list.splitDates(list.params);

        $.get('/services/list', list.params).then(response => {
            if ( ajaxId !== list.loadId ) {
                return;
            }
            response = JSON.parse(response);

            list.total = response.total;
            list.$list[0].innerHTML = response.html + pagination({
                currentPage: list.params._page,
                total: list.total
            });

            // functions.initCompare();
            // list.setResultsText();
            list.allowInfoWindow = true;
            list.domInits();
            list.unsetLoadingMode();
        });
    },
    setResultsText() {
        let maxResults = list.params._page * 10;
        list.$shownItems.html(
            (maxResults - 9) +
            ' - ' +
            (maxResults > list.total ? list.total : maxResults)
        );
        list.$total.html(list.total);
    },
    domInits() {
        
        let listSlider = [];
        $('[id^=list-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            listSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: true,
                arrows: true,
                slidesPerView: 1,
                spacing: 0,
                pager: false
            });
        });

        let $dropdown = $(".dropdown-sort-by");
        $dropdown.find('.dropdown-item').on('click', function (e) {
            e.preventDefault();
            list.params._page = 1;
            list.params.sortBy = $(e.currentTarget).attr('href');
            if ( list.$body.length ) {
                functions.setUrlData(list.params, true, list.defaults);
            } else {
                list.paginated = true;
                list.$form.submit();
            }
        });

        if (list.params.sortBy) {
            let text = $dropdown.find(".dropdown-item[href='"+list.params.sortBy+"']").html();
            $dropdown.find(".btn span").html(text);
        }

        observer.observe();
    },
    clearMarkers() 
    {
        //Loop through all the markers and remove
        for (var i = 0; i < list.markers.length; i++) {
            list.markers[i].setMap(null);
        }
        list.markers = null;
    },
    closeModal()
    {
        var modal = Modal.getInstance($('#filtersModal'));
        modal.hide();
        $('.modal-backdrop').remove();
    }
    
};

$(() => {
    list.init();
    list.loadMap();
})