import functions from './functions.js';
import Slider from "./Slider";
import lozad from 'lozad';
import {SimpleGuests, SimpleGuestsDefaults} from "./simple-guests.js";

const lang = functions.getLang();
const trans = {
    'en' : {
        'adults' : 'Adults',
        'children' : 'Children',
        'guests' : 'guests',
        'pets' : 'pets',
        'noGuestLabel' : 'Enter number of guests'
    },
    'hr' : {
        'adults' : 'Odrasli',
        'children' : 'Djeca',
        'guests' : 'gostiju',
        'pets' : 'Ljubimci',
        'noGuestLabel' : 'Unesite broj gostiju'
    },
    'de' : {
        'adults' : 'Erwachsene',
        'children' : 'Kinder',
        'guests' : 'Gäste',
        'pets' : 'Haustiere',
        'noGuestLabel' : 'Geben Sie die Anzahl der Gäste ein'

    }
};

SimpleGuestsDefaults.maxInfants = 0;
SimpleGuestsDefaults.adultsLabel = trans[lang].adults;
SimpleGuestsDefaults.childrenLabel = trans[lang].children;
SimpleGuestsDefaults.guestsLabel = trans[lang].guests;
SimpleGuestsDefaults.petsLabel = trans[lang].pets;
SimpleGuestsDefaults.noGuestLabel = trans[lang].noGuestLabel;

$(function() {

    $('[name="dates"]').on('change', e => {
        const $target = $(e.currentTarget);
        let value = $target.val();
        const $datepicker = $target.parents('.datepicker-range');
        const $dataClear = $datepicker.find('[data-clear]');
        value ? $dataClear.addClass('has-value') : $dataClear.removeClass('has-value');
    });

    functions.isMobile() ? $('.show-input-desktop').remove() : $('.show-input-mobile').remove();

    $('#navbarTogglerDemo01').on('show.bs.collapse', e => {
        $('header .navbar').addClass('bg-dark');
    });
    $('#navbarTogglerDemo01').on('hide.bs.collapse', e => {
        $('header .navbar').removeClass('bg-dark');
    });

    $('body').on('click', '.dropdown-menu', e => {
        e.stopPropagation();
    });

    $('.simple-guests').each((i, elem) => {
		new SimpleGuests(elem);
	});

    let homeSlider = [];
    $('[id=home-slider]').each((i, elem) => {
        //const length = $(elem).find('.keen-slider__slide').length;
        homeSlider[i] = new Slider('#' + $(elem).attr('id'), {
            loop: true,
            fade: true,
            arrows: true,
            slides: {
                perView: 1,
            },
            autoplay: 6000,
            //autoplay: 0,
            defaultAnimation: {
              duration: 1500,
            },
            detailsChanged: (s) => {
              s.slides.forEach((element, idx) => {
                element.style.opacity = s.track.details.slides[idx].portion
              })
            },
            renderMode: "custom",
        });
    });

    let catalogueSlider = [];
    $('[id^=catalogue-slider]').each((i, elem) => {
        //const length = $(elem).find('.keen-slider__slide').length;
        catalogueSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            pager: true,
            slides: {
                //origin: "center",
                perView: 3,
                spacing: 30,
            }
        });
    });

    let reviewsSlider = [];
    $('[id^=reviews-slider]').each((i, elem) => {
        //const length = $(elem).find('.keen-slider__slide').length;
        reviewsSlider[i] = new Slider('#' + $(elem).attr('id'), {
            mode: "free-snap",
            rubberband: false,
            loop: true,
            arrows: true,
            pager: true,
            slides: {
                //origin: "center",
                //perView: 1,
                //perView: "auto",
                perView: functions.isMobile() ? 1.1 : 1,
                spacing: functions.isMobile() ? 8 : 30,
            }
        });
    });

    let destinationsSlider = [];
    $('[id^=destinations-slider]').each((i, elem) => {
        //const length = $(elem).find('.keen-slider__slide').length;
        destinationsSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            pager: true,
            slides: {
                //origin: "center",
                perView: 2.05,
                spacing: 30,
            }
        });
    });

    let listSlider = [];
    $('[id^=list-slider]').each((i, elem) => {
        const length = $(elem).find('.keen-slider__slide').length;
        listSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: true,
            arrows: true,
            slidesPerView: 1,
            spacing: 0,
            pager: false
        });
    });

    /* gallery */
    const lg = {};
    window.showGallery = (index, id) => {
        index = +index || 0;
        if ( lg[id] ) {
            lg[id].index = index;
            lg[id].build(index);
        } else {
            let $elem = $('[data-images-' + id + ']');
            lg[id] = $elem.lightGallery({
                dynamic: true,
                thumbnail: functions.isMobile() ? false : true,
                toogleThumb: true,
                thumbWidth: 130,
                thumbMargin: 10,
                download: false,
                fullScreen : true,
                preload: 2,
                index: index,
                dynamicEl: JSON.parse($elem.attr('data-images-' + id))
            }).data('lightGallery');
        }
    };
    $('[data-gallery-id]').on('click', function (e) {
        e.preventDefault();
        let galleryId = $(this).attr('data-gallery-id');
        galleryId && showGallery($(this).attr('data-index'), galleryId);
    });
    // don't focus div with gallery
    $('[data-gallery-id]').on('focus', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        return false;
    });
    /* end gallery */
    
    // menu links active
    const path = location.pathname.replace(/\/$/, '');
    $('.main-header [href], footer [href]').each((i, link) => {
        let href = link.getAttribute('href');
        if ( href && href != '#' ) {
            href = href.split('?')[0].replace(/\/$/, '');
            if ( path === href ) {
                $(link).addClass('active').closest('.nav-item').addClass('active');
            }
        }
    });

    // global tooltip init
   //('[data-toggle="tooltip"]').tooltip();

    // mobile menu handling
    (() => {
        const $header = $('.main-header');
        const $collapser = $('#main-menu');

        // ensure navbar toggler also open closes main-menu
        $collapser
            .on('show.bs.collapse', () => {
                document.body.classList.add('modal-transition');
                document.body.classList.add('modal-active');
            })
            .on('hide.bs.collapse', () => {
                document.body.classList.remove('modal-active');
            })
            .on('hidden.bs.collapse', () => {
                document.body.classList.remove('modal-transition');
            });

        // click on "overlay" closes menu
        $header.find('.header-overlay').on('click', () => {
            $collapser.collapse('hide');
        });
    })();

    // scrool to element handling
    $(document).on('click', '[data-scroll-to]', e => {
        e.preventDefault();
        const $this = $(e.target);
        let scrollTo = $this.attr('data-scroll-to').split(',');
        functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
    });
    
    // global datepicker init with automatic "dateTo" set if provided
    let displayDate = new Date('2019-06-01');
    let now = functions.cleanDate(new Date);
    if ( now > displayDate ) {
        displayDate = now;
    }

    $('.datepicker').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        $datepicker.flatpickr({
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            minDate: minDate ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-angle-left pr-2"></i>',
            nextArrow: '<i class="la la-angle-right pl-2"></i>',
            onChange(selectedDates, dateStr, instance) {

                const $first = $(instance.input);
                // prevent other logic if second datepicker is triggered by first
                if (instance._openByFirst) {
                    delete instance._openByFirst;
                    return;
                }
                
                if ($first.attr('data-firstDate')) {
                    const $second = $first.closest('form').find('[data-secondDate]').closest('.datepicker');
                    if ($second.length) {

                        var firstDate = new Date(selectedDates[0]);
                        firstDate.setDate(firstDate.getDate() + 1);
                        
                        var secondInstance = $second[0]._flatpickr;
                        // check if firstDate is invalid
                        if (firstDate.getTime() === firstDate.getTime()) {
                            secondInstance._openByFirst = true;
                            secondInstance.set('minDate', firstDate);
                            if (!secondInstance.selectedDates[0]) {
                                firstDate.setDate(firstDate.getDate() + 6);
                                secondInstance.setDate(firstDate);
                            }
                            secondInstance.open();
                            $(secondInstance.altInput).focus();
                        }
                        else if (secondInstance.selectedDates[0]) {
                            secondInstance.clear();
                        }
                    }
                }
                // check if second date is cleared
                else if ($first.attr('data-secondDate') && !selectedDates[0]) {
                    $first.closest('form').find('[data-firstDate]').closest('.datepicker')[0]._flatpickr.clear();
                }
            },
            onOpen(selectedDates, dateStr, instance) {
                if (instance.input.getAttribute('data-secondDate')) {
                    const $first = $(instance.input).closest('form').find('[data-firstDate]').closest('.datepicker');
                    if ($first.length) {
                        const firstInstance = $first[0]._flatpickr;
                        if (!firstInstance.input.value) {
                            instance.close();
                            firstInstance.open();
                            firstInstance.altInput.focus();
                            return;
                        }
                    }
                }
                !dateStr && instance.jumpToDate(minDate ? minDate : displayDate);
            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    }); 

    $('.datepicker-range').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
        const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
        const duration = Number($datepicker.attr('data-duration'));
        $datepicker.flatpickr({
            mode: "range",
            showMonths: functions.isMobile() ? 1 : 2,
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            defaultDate: defaultDate,
            minDate: minDate && minDate >= now ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-arrow-circle-left la-fw la-2x pr-2"></i>',
            nextArrow: '<i class="la la-arrow-circle-right la-fw la-2x pl-2"></i>',
            locale: {
                rangeSeparator: ' - '
            },
            onDayCreate(dObj, dStr, fp, dayElem){

                let to = new Date(maxDate);
                let weekday =  new Date(dayElem.dateObj).getDay();
                weekday = '' + (weekday ? weekday : 7);
                to.setHours(0, 0, 0, 0);

                if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                    dayElem.classList.add('unselectable');
                }
            },
            onChange(selectedDates, dateStr, instance) {

                /*const picker = $datepicker[0]._flatpickr;

                if(selectedDates.length < 2) {
                    const firstDate = new Date(selectedDates[0]);
                    const secondDate = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() + duration);

                    //instance.set('maxDate', secondDate);
    
                    picker.setDate([firstDate, secondDate], false);

                    instance.close();
                }*/

            },
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });

    /*let userDates = functions.getCookie('user_dates');
    if(userDates) {
        let userDateFrom = new Date(userDates.split(' - ')[0]);
        let today = new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate() );

        if(userDateFrom < today) {
            functions.setCookie('user_dates', '');
            userDates = functions.getCookie('user_dates');
        }
    }

     let datepicker = $('.datepicker-range')[0];

     if(userDates && datepicker) {
        userDates = userDates.split(' - ');
        let dateFrom = userDates[0] ? userDates[0] : null;
        let dateTo = userDates[1] ? userDates[1] : null;

        if(dateFrom && dateTo) {
            datepicker._flatpickr.setDate([dateFrom, dateTo]);
        } else if(dateFrom) {
            datepicker._flatpickr.setDate([dateFrom, dateFrom]);
        }
     }

     $('[name="dates"]').on('change', e => {
        functions.setCookie('user_dates', $(e.target).val());
    });*/

    // select2 templates can be defined as data-template="destinations" ...
    const selectTemplates = {
        destinations: data => {
            if ( !data.element ) {
                return data.text;
            }
            if(data.placeId && data.placeName) {
                //const $element = $(data.element).addClass(data.element.className).text(data.placeName);
                const $element = $('<span>').addClass(data.element.className).text(data.placeName);
                return $element;
            }
            if(data.text) {
                const $element = $('<span>').addClass(data.element.className).text(data.text);
                let elemClass;
                switch ( (data.id).split(':').length ) {
                    case 1: elemClass = ''; break;
                    case 2: elemClass = ''; break;
                    case 3: elemClass = ''; break;
                    case 4: elemClass = ''; break;
                    default: elemClass = 'h5'; break;
                }
                $element.addClass(elemClass);
                return $element;
            }
        },
    };

    // global init select2
    // data-templates
    // data-data
    // data-allow-clear
    // data-nosearch
    // placeholder
    $.map($('.select2'), elem => {
        const $elem = $(elem);
        const config = { dropdownParent: $elem.parent(), language: functions.getLang() };
        config.allowClear = $elem.attr('data-allow-clear');

        // find select2 which has an attribute data-data
        const data = $elem.attr('data-data');
        if ( data ) {
            try {
                config.data = JSON.parse(data);
            } catch (e) {}
        } 
        else if ( $elem.attr('data-special') ) {
            let newData = $('body').attr('data-select-' + $elem.attr('data-special'));
            config.data = JSON.parse(newData);
        }

        if ( $elem.attr('data-template') ) {
            config.templateResult = selectTemplates[$elem.attr('data-template')];
        }
        if ( $elem.attr('data-template-selection') ) {
            config.templateSelection = selectTemplates[$elem.attr('data-template-selection')];
        }

        if ( $elem.attr('data-nosearch') !== undefined ) {
            config.minimumResultsForSearch = Infinity;
        }

        let placeholder = $elem.attr('placeholder');
        $elem.removeAttr('placeholder');
        config.placeholder = placeholder || ' ';
        
        $elem.select2(config)
            .on('select2:clear', () => {
                $elem[0].cleared = true;
            })
            .on('select2:opening', e => {
                if ( $elem[0].cleared ) {
                    e.preventDefault();
                    $elem[0].cleared = false;
                }
            });
    });
    
    // advanced form controls
    // input-popover
    $('body').on('click', '.dropdown-menu-popover', e => {
        e.stopPropagation();
    });
    //inputPopover.apply();
    //inputGuests.apply();

    // button checkbox
    /*$('.btn-checkbox').on('click', e => {
        let input = $(e.target).prev()[0];
        input.checked = !input.checked;
    });*/

    $('#cookie-bar-accept').on('click', function (e) {
        functions.setCookie('cookie_usage', 'accepted');
        $("#cookie-bar").addClass('d-none');
    });

    /*$('.currency').on('click', function (e) {
        functions.setCookie('currency', $(this).data('currency'));
        location.reload();
    });*/

    $('.currency-change').on('click', function (e) {
        functions.setCookie('currencyIdTo', e.target.id);
        location.reload();
    });

    window.showmoreobserver = lozad('.show-more', {
        rootMargin: '200px 200px',
        loaded: elem =>
        {
            $(elem).triggerHandler('lozadLoaded');
            let $showMore = $(elem);

            let height = $showMore.attr('data-height');

            if ($showMore.find('.show-more-content').outerHeight() < height)
            {
                $showMore.addClass('removeBefore');
                $showMore.next('.show-more-expand').remove();
                return;
            }

            let $breakpoint = $showMore.find('.show-more-breakpoint');
            if (height)
            {
                if (functions.isMobile()) height = height * 0.75;

                $showMore.css('max-height', +height);
            }
            else if ($breakpoint.length)
            {
                $showMore.css('max-height', $breakpoint.offset().top - $showMore.offset().top - 25);
            }
            else
            {
                let $maxHeight = functions.isMobile() ?
                    ($showMore.outerHeight() * 0.15) :
                    ($showMore.outerHeight() * 0.3);

                $showMore.css('max-height', $maxHeight);
            }

            $showMore.next('.show-more-expand').on('click', e =>
            {
                let $prevSibling = $(e.currentTarget).prev();
                let $maxHeight
                if ($prevSibling.hasClass('removeBefore'))
                {
                    let height = $prevSibling.attr('data-height');
                    let $breakpoint = $prevSibling.find('.show-more-breakpoint');
                    if (height)
                    {
                        $maxHeight = +height;
                        if (functions.isMobile()) $maxHeight = $maxHeight * 0.75;
                    }
                    else if ($breakpoint.length)
                    {
                        $maxHeight = $breakpoint.offset().top - $showMore.offset().top - 25;
                    }
                    else
                    {
                        $maxHeight = functions.isMobile() ?
                            ($prevSibling.outerHeight() * 0.15) :
                            ($prevSibling.outerHeight() * 0.3);
                    }

                    $prevSibling.css('max-height', $maxHeight);
                    $prevSibling.removeClass('removeBefore');
                    if ($prevSibling.hasClass('show-more-scroll') && functions.isMobile())
                    {
                        let scrollDistance = $prevSibling.offset().top - 80;
                        $('html, body').stop().animate({
                            scrollTop: scrollDistance,
                        }, 0, 'linear');
                    }
                }
                else
                {
                    let $content = $prevSibling.find('.show-more-content');
                    $prevSibling.css('max-height', $content.outerHeight());
                    $prevSibling.addClass('removeBefore');
                }
            })
        }
    });
    window.showmoreobserver.observe();
 
});