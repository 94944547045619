import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
        InnerBlocks,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
        Button
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-experience', {
        // built-in attributes
        title: 'Card Experience',
        description: 'Custom Card Experience',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            buttonText: {
                type: 'string',
                default: 'See more'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                link,
                image,
                alt,
                title,
                description,
                buttonText,
                classes
            } = attributes;

            const ALLOWED_BLOCKS = ['core/heading', 'core/paragraph'];
            const MY_TEMPLATE = [
                [ 'core/heading', { placeholder: 'Card title', className: 'card-title' } ],
                [ 'core/group', { className: 'card-description' }, [
                    [ 'core/paragraph', { placeholder: 'Card text' } ]
                ] ]
            ];

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({image: image.sizes.full.url});
            }

            function onAltChange(alt) {
                setAttributes({alt: alt});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onButtonTextChange(buttonText) {
                setAttributes({buttonText: buttonText});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <div>
                                    { image && 
                                        <div>
                                            <figure>
                                                <img src={image} />
                                            </figure>
                                            <div>
                                                <Button onClick={() => setAttributes({ image: '' })} className="button">Remove</Button>
                                            </div>
                                            <br></br>
                                        </div>
                                
                                    }
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                                </div>
                            ) } />
                        <br />
                        <p><strong>Alt image:</strong></p>
                        <TextControl value={ alt } onChange={ onAltChange }></TextControl>
                        <br />
                        <p><strong>Enter url: (ex: /camps/)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <br />
                        <p><strong>Button Text</strong></p>
                        <TextControl value={ buttonText } onChange={ onButtonTextChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <a className={ "card card-experience" + (classes ? ' ' + classes : '') }>
                    <div class="card-img-top">
                        <img class="cover-image" src={ image } alt={ alt } />
                    </div>
                    <div class="card-body">
                        <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } templateLock="all" />
                        <RichText 
                            key="editable" 
                            tagName="button"
                            className="btn btn-outline-dark"
                            placeholder="See more" 
                            value={ buttonText }
                            onChange={ onButtonTextChange } />
                    </div>
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                image,
                alt,
                buttonText,
                classes
            } = attributes;

            return (
                <a href={ link } className={ "card card-experience" + (classes ? ' ' + classes : '') }>
                    <div class="card-img-top">
                        <img class="lozad cover-image" data-src={ image } alt={ alt } />
                    </div>
                    <div class="card-body">
                        <InnerBlocks.Content />
                        <button class="btn btn-outline-dark">{ buttonText }</button>
                    </div>
                </a>
            );
        },
    });
}