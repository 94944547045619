import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const {
        InspectorControls,
        InnerBlocks
    } = window.wp.editor;

    const { 
        PanelBody,
        SelectControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/hero-header', {
        // built-in attributes
        title: 'Hero Header',
        description: 'Custom Hero Header',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            type: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                type,
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onTypeChange(type) {
                setAttributes({type: type});
            }

            const ALLOWED_BLOCKS = ['core/heading', 'core/paragraph'];
            const MY_TEMPLATE = [
                [ 'core/heading', { placeholder: 'Hero subtitle', className: 'hero-subtitle' } ],
                [ 'core/heading', { placeholder: 'Hero title', className: 'hero-title' } ],
                [ 'core/group', { className: 'hero-description' }, [
                    [ 'core/paragraph', { placeholder: 'Hero text' } ]
                ] ]
            ];

            return (
                <div className="home-hero">
                    <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } templateLock="all" />
                </div>
            );
        },

        save({ attributes }) {

            const {
                type,
                classes
            } = attributes;

            return (
                <div className={ "home-hero " + type + (classes ? ' ' + classes : '') }>
                    <div id="hero-slider" className="keen-slider">
                        <InnerBlocks.Content />
                    </div>
                </div>
            );
        },
    });
}