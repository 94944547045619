import functions from "./functions.js";
import tplLoader from "./tpl-loader.js";

require("babel-core/register");
require("@babel/polyfill");

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        totalAmount: null,
        totalAmountConverted: null,
        $payment: null,
        $calculation: null,
        subscribed: false,
        urlParams: null,
        calc: [],
        persons: null,

        init() {
            booking.$form = $(".booking-form");
            booking.$calculation = $(".table-calculation");
            booking.$payment = $(".payment-details");
            booking.params = {};

            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            booking.$calculation.find('input[type="checkbox"]').change(function (e) {
                let $row = $(this).closest('tr');
                let $quantity = $row.find('select.quantity');
                let value = +$quantity.val();
                let checked = $(this).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(this));
            });


            booking.$calculation.find('select.quantity').change(function (e) {
                let $row = $(this).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(this).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(this));
            });

            booking.total();


            if (booking.$payment.length) {
                booking.params.paymentType = 'creditCard';
                booking.$payment.find('input[name="paymentType"]').change(function (e) {
                    booking.params.paymentType = booking.$payment.find('input[name="paymentType"]:checked').val();
                });
            }

            $('.payment-type-wrapper').on('click', function (e) {
                let labelTag = $(e.target).closest('label').attr('for');
                let $labels = $(this).find('label');
                $labels.each((index, item) => {
                    let $elem = $(item);
                    if ($elem.attr('for') == labelTag)
                    {
                        $elem.removeClass('border-white').addClass('border-primary');
                    }
                    else
                    {
                        $elem.removeClass('border-primary').addClass('border-white');
                    }
                    let $transferInfo = $elem.find('.bank-transfer-info');
                    if($transferInfo.length) $transferInfo.toggleClass('active', $elem.attr('for') == labelTag)
                })
            });


            booking.$form.on('submit', e => {
                // e.preventDefault()

                let $paymentTypeForm = $('.payment-details');
                if ( ! $paymentTypeForm[0].checkValidity()) {
                    
                    var tmpSubmit = document.createElement('button');
                    $paymentTypeForm[0].appendChild(tmpSubmit);
                    tmpSubmit.click();
                    $paymentTypeForm[0].removeChild(tmpSubmit);

                    e.preventDefault();
                    return;
                }


                $('.booking-btn').attr('disabled', 'disabled');
                booking.submit(e);
            });

            $(".btn-promo-code").on('click', e => {
                let params = {};
                params['language'] = functions.getLang();
                params['promoCode'] = $("input[name='promoCode']").val();

                let totalAmount = 0;

                $.each(booking.calc, function (key, calc) {
                    if (!calc.onSpot && calc.inCalc) {
                        totalAmount += booking.itemTotal(calc);
                    }
                });

                params.total = totalAmount;

                $.post('/services/promoCode/', params).then(response => {
                    $(".promo-code-output").removeClass('d-none').html(response.data);
                    if (response.status == true) {
                        booking.promoCodeData = response;
                        booking.promoCodeData.promoCode = params['promoCode'];

                        $(".btn-promo-code").attr('disabled', true);
                        booking.applyDiscount(params['promoCode']);
                    }
                });
            });

            let params = functions.getUrlData(false);
            if (params.numberOfPets == 'undefined') params.numberOfPets = 0;
            
            functions.setFormData(booking.$form[0], params)

            $('[name="guests"]').on('change', e => {

                if (booking.$calculation.length) {
                    $('.booking-pricing-container').addClass('loading ');
                    $('.booking-pricing-container').prepend(tplLoader);
                }

                e.preventDefault();
                const $target = $(e.target);
                let guesetsVal = $target.val().split(',');
                let $guestsElem = $('.booking-guests');
                if (guesetsVal[0]) $guestsElem.find('.adults > .number').html(guesetsVal[0]);
                if (guesetsVal[1] != 0)
                {
                    $guestsElem.find('.children > .number').html(guesetsVal[1]);
                    $guestsElem.find('.children').removeClass('d-none');
                }
                else 
                {
                    $guestsElem.find('.children').addClass('d-none');
                }


                let params = functions.getUrlData(false);
                params.guests = $target.val();
                params.language = functions.getLang();

                let $pets = $('[name="numberOfPets"]');
                params.numberOfPets = $pets.val();
                params.addAllOptionals = true;

                // booking.setLoadingMode();
                let oldTotal = $('.total-amount').html().replace(/\s/g, '');
                params.addAllOptionals = 1; 
                $.get('/services/recalculate/', $.param(params)).then(response => {
                    response = JSON.parse(response);
                    if (response.status) {
                        booking.calc = [];
                        setTimeout(() => {
                            $('.booking-pricing-container').html(response.html);
                            $(".btn-promo-code").attr('disabled', false);
                            $(".promo-code-output").addClass('d-none').html('');

                            
                            booking.calc = [];
                            booking.$form = $(".booking-form");
                            booking.$calculation = $(".table-calculation");

                            booking.$calculation.find("*[data-calc]").each(function (index) {
                                let item = JSON.parse($(this).attr('data-calc'));
                                item.inCalc = item.isOptional ? 0 : 1;
                                booking.calc.push(item);
                            });

                            booking.$calculation.find('input[type="checkbox"]').change(function (e) {
                                let $row = $(this).closest('tr');
                                let $quantity = $row.find('select.quantity');
                                let value = +$quantity.val();
                                let checked = $(this).is(':checked') ? 1 : 0;
                
                                if (checked && !value) {
                                    $quantity.prop('value', 1);
                                } else if (!checked && value) {
                                    $quantity.prop('value', 0);
                                }
                
                                booking.calculate($(this));
                            });
                        }, 350);
            
            
            
                        booking.$calculation.find('select.quantity').change(function (e) {
                            let $row = $(this).closest('tr');
                            let $checkbox = $row.find('input[type="checkbox"]');
                            let value = +$(this).val();
            
                            if (value && !$checkbox.is(':checked')) {
                                $checkbox.prop('checked', true);
                            } else if (!value && $checkbox.is(':checked')) {
                                $checkbox.prop('checked', false);
                            }
            
                            booking.calculate($(this));
                        });

                        booking.total();
                    }
                    
                });

                // details.params.guests = $target.val();
                // functions.setUrlData(details.params, true, true);
            });

        },
        applyDiscount(promoCodeName) {

            booking.$calculation.each(function (index) {

                $(this).find(".promoDiscountItem").remove();
                let promoDiscountItem = JSON.parse($(this).find("*[data-calc]:first").attr('data-calc'));
                let promoDiscountItemId = promoDiscountItem.id + '_' + 114;

                let total = 0;
                let totalIn = 0;
                $(this).find("*[data-calc]").each(function (index) {
                    let item = JSON.parse($(this).attr('data-calc'));
                    let quantity = item.isOptional ? $(this).find('select.quantity').val() : item.quantity;
                    if ( ! item.onSpot && quantity) {
                        total += +item.price * +quantity;
                        totalIn += +item.priceIn * +quantity;
                    }
                });

                $.each(booking.calc, function (key, calc) {
                    if (calc && calc.id == promoDiscountItemId) {
                        booking.calc.splice(key, 1);
                    }
                });

                let discountAmount, discountAmountIn = null;

                if (booking.promoCodeData.percent || null)
                {
                    discountAmount = (-1 * (total * (booking.promoCodeData.percent/100)));
                    discountAmountIn = (-1 * (totalIn * (booking.promoCodeData.percent/100)));
                }

                if (booking.promoCodeData.price || null)
                {
                    discountAmount = -1 * booking.promoCodeData.price;
                    discountAmountIn = -1 * booking.promoCodeData.price;
                }

                promoDiscountItem.id = promoDiscountItemId;
                promoDiscountItem.itemDefinitionId.id = 114;

                if (promoCodeName.toLowerCase() === 'chiavalon5')
                {
                    promoDiscountItem.itemDefinitionId.text = 'ISTRIALUX CHIAVALON CARD';
                    promoDiscountItem.itemTitle = {
                        name_hr:'ISTRIALUX CHIAVALON CARD'
                    };
                }
                else if(promoCodeName.toLowerCase() === 'blackfriday100')
                {
                    promoDiscountItem.itemDefinitionId.text = 'BLACK FRIDAY CARD';
                    promoDiscountItem.itemTitle = {
                        name_hr:'BLACK FRIDAY CARD'
                    };
                }
                else 
                {
                    promoDiscountItem.itemDefinitionId.text = 'ISTRIALUX FAMILY CARD';
                    promoDiscountItem.itemTitle = {
                        name_hr:'ISTRIALUX FAMILY CARD'
                    };
                }

                
                promoDiscountItem.itemDefinitionId.categoryId.id = 80;
                promoDiscountItem.itemDefinitionId.categoryId.key = 'discount';
                promoDiscountItem.itemDefinitionId.objectGroupId.id = 1;
                promoDiscountItem.itemDefinitionId.objectGroupId.key = 'accommodation';
                promoDiscountItem.itemDefinitionId.typeId.id = 3;
                promoDiscountItem.itemDefinitionId.typeId.key = 'discount';
                promoDiscountItem.priceIn = booking.promoCodeData.applyToPriceIn ? discountAmountIn : 0;
                promoDiscountItem.price = discountAmount;
                promoDiscountItem.totalIn = booking.promoCodeData.applyToPriceIn ? discountAmountIn : 0;
                promoDiscountItem.total = discountAmount;
                promoDiscountItem.noteHidden = booking.promoCodeData.promoCode;
                promoDiscountItem.skipCalculate = true;

                $('<tr class="promoDiscountItem" data-calc=\'' + JSON.stringify(promoDiscountItem) + '\'>\n' +
                    '        <td class="px-3">\n' +
                    '            <div class="custom-control custom-checkbox">\n' +
                    '                <input type="checkbox" class="custom-control-input" id="' + promoDiscountItem.id + '" name="' + promoDiscountItem.id + '" checked="" disabled="">\n' +
                    '                <label class="custom-control-label" for="' + promoDiscountItem.id + '"></label>\n' +
                    '            </div>\n' +
                    '        </td>\n' +
                    '        <td class="px-3">\n' +
                    '            <div>\n' +
                    '                ' + promoDiscountItem.itemDefinitionId.text + '\n' +
                    '            </div>\n' +
                    '        </td>\n' +
                    '        <td class="px-3 text-center text-nowrap d-non d-lg-table-cell">\n' +
                    '                            1\n' +
                    '                    </td>\n' +
                    '        <td class="px-3 text-end text-nowrap d-none d-lg-table-cell">\n' +
                    '            ' + functions.formatMoney(discountAmount, 2, ',', '.') + ' €\n' +
                    '        </td>\n' +
                    '        <td class="px-3 text-end text-nowrap d-non d-lg-table-cell">\n' +
                    '            <span class="item-total-amount">' + functions.formatMoney(discountAmount, 2, ',', '.') + '</span>  €\n' +
                    '        </td>\n' +
                    '    </tr>').insertBefore($(this).find("*.total-row"));

                booking.calc.push(promoDiscountItem);
                booking.calculate($(this).find(".promoDiscountItem").find("input[type='checkbox']"));
            });

        },
        async submit(e) {
            
            if ($('[name="newsletter"]').length && $('[name="newsletter"]').is(":checked") && ! this.subscribed)
            {
                e.preventDefault();
                let email = $('[name="email"]').val();
                const results = await $.post('/services/mailchimp/', {email: email, language: functions.getLang()});
                if (! results.status)
                {
                    $('.booking-btn').attr('disabled', false);
                    $('.response-status').removeClass('d-none').html(results.data);
                    setTimeout(() => {
                        $('.response-status').addClass('d-none').html('');
                    }, 10000);

                    return;
                }
                else
                {
                    this.subscribed = true;
                    booking.$form.submit();
                }

                
            }

            let petsTtitle = {
                hr: 'Kućni ljubimci: ',
                en: 'Pets: ',
                de: 'Haustiere: ',
            }

            let formParams = functions.getFormData(booking.$form);
            if (formParams.numberOfPets == 'undefined') formParams.numberOfPets = 0;

            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                } else if (item.itemDefinitionId.categoryId.key == 'baseRate') {
                    let $guestsPerProduct = $("[data-guests-parentId='" + item.parentId + "']");
                    let guests = [];

                    if ($guestsPerProduct.length) {
                        $guestsPerProduct.find('[data-guests]').each(function (index) {
                            let g = +$(this).data('guests');
                            guests[index] = {
                                firstName : $(this).find("input[name='firstName" + g + "']").val(),
                                lastName : $(this).find("input[name='lastName" + g + "']").val(),
                                age : $(this).find("input[name='age" + g + "']").val()
                            };
                        });
                        booking.calc[key].guestsData = guests;
                    }

                    if (formParams.numberOfPets) 
                    {
                        booking.calc[key].notePublic = (petsTtitle[functions.getLang()] || 'Pets: ') + formParams.numberOfPets;
                        booking.calc[key].noteSupplier = (petsTtitle[($('.booking-pricing-container').attr('data-language') || 'hr')]) + formParams.numberOfPets;
                    }
                }
            });
            
            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

        },
        calculate($elem) {
            let $row = $elem.closest('tr');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            let id = $checkbox.attr('id');

            let $quantity = $row.find('select.quantity');
            let quantity = null;

            if ($quantity.length) {
                quantity = +$quantity.val();
            }


            $.each(booking.calc, function (key, calc) {
                if (calc.id == id) {

                    if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }

                    booking.calc[key].inCalc = checked;
                    booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(booking.calc[key].total, 2, ',', '.'));
                }
            });

            booking.total();
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },
        itemTotalConverted(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.priceConverted;
                } else {
                    itemTotal = +item.priceConverted;
                }
            } else {
                itemTotal = +item.quantity * +item.priceConverted;
            }

            return itemTotal;
        },
        total() {
            const $e = $('.payment-details');

            const advancePaymentPercent = $e.attr('data-advancepaymentpercent');
            const commissionForCreditCard = +$e.attr('data-commissionforcreditcard');

            let totalAmount = 0.00;
            let totalAmountConverted = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    totalAmount += booking.itemTotal(calc);
                    totalAmountConverted += booking.itemTotalConverted(calc);
                }
            });

            let totalAmountCard = totalAmount + ((totalAmount * commissionForCreditCard ) / 100);
            let totalAmountCardConverted = totalAmountConverted + ((totalAmountConverted * commissionForCreditCard ) / 100);

            let advanceCard = totalAmountCard * (advancePaymentPercent / 100);
            let restCard = totalAmountCard - advanceCard;
            let advanceCardConverted = totalAmountCardConverted * (advancePaymentPercent / 100);
            let restCardConverted = totalAmountCardConverted - advanceCardConverted;

            let advance = totalAmount * (advancePaymentPercent / 100);
            let rest = totalAmount - advance;
            let advanceConverted = totalAmountConverted * (advancePaymentPercent / 100);
            let restConverted = totalAmountConverted - advanceConverted;

            $('.total-amount').html(functions.formatMoney(totalAmount, 2, ',', '.'));
            $('.total-amount-converted').html(functions.formatMoney(totalAmountConverted, 2, ',', '.'));

            $("#credit-card-card-body").find('.advance').html(functions.formatMoney(advanceCard, 2, ',', '.'));
            $("#credit-card-card-body").find('.rest').html(functions.formatMoney(restCard, 2, ',', '.'));
            $("#bank-transfer-card-body").find('.advance').html(functions.formatMoney(advance, 2, ',', '.'));
            $("#bank-transfer-card-body").find('.rest').html(functions.formatMoney(rest, 2, ',', '.'));

            $("#credit-card-card-body").find('.advance-converted').html(functions.formatMoney(advanceCardConverted, 2, ',', '.'));
            $("#credit-card-card-body").find('.rest-converted').html(functions.formatMoney(restCardConverted, 2, ',', '.'));
            $("#bank-transfer-card-body").find('.advance-converted').html(functions.formatMoney(advanceConverted, 2, ',', '.'));
            $("#bank-transfer-card-body").find('.rest-converted').html(functions.formatMoney(restConverted, 2, ',', '.'));

        }
    }

    booking.init();
});