import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
        SelectControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/button-cta', {
        // built-in attributes
        title: 'Button CTA',
        description: 'Button CTA',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            text: {
                type: 'string',
                default: 'Text'
            },
            type: {
                type: 'string',
                default: 'btn-primary'
            },
            size: {
                type: 'string',
                default: ''
            }
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                link,
                text,
                type,
                size,
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            }
            function onTextChange(text) {
                setAttributes({text: text});
            }
            function onTypeChange(type) {
                setAttributes({type: type});
            }
            function onSizeChange(size) {
                setAttributes({size: size});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Enter url: (ex: /camps)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <p><strong>Text</strong></p>
                        <TextControl value={ text } onChange={ onTextChange }></TextControl>
                        <p><strong>Type</strong></p>
                        <SelectControl value={ type } onChange={ onTypeChange } options={ [
                            { label: 'Primary', value: 'btn-primary' },
                            { label: 'Info', value: 'btn-info' },
                            { label: 'Success', value: 'btn-success' },
                            { label: 'Warning', value: 'btn-warning' },
                            { label: 'Danger', value: 'btn-danger' },
                            { label: 'Dark', value: 'btn-dark' },
                            { label: 'Light', value: 'btn-light' },
                            { label: 'White', value: 'btn-white' },
                            { label: 'Outline Primary', value: 'btn-outline-primary' },
                            { label: 'Outline Info', value: 'btn-outline-info' },
                            { label: 'Outline Success', value: 'btn-outline-success' },
                            { label: 'Outline Warning', value: 'btn-outline-warning' },
                            { label: 'Outline Danger', value: 'btn-outline-danger' },
                            { label: 'Outline Dark', value: 'btn-outline-dark' },
                            { label: 'Outline Light', value: 'btn-outline-light' },
                            { label: 'Outline White', value: 'btn-outline-white' }
                        ] } />
                        <p><strong>Size</strong></p>
                        <SelectControl value={ size } onChange={ onSizeChange } options={ [
                            { label: 'Normal', value: '' },
                            { label: 'Small', value: 'btn-sm' },
                            { label: 'Large', value: 'btn-lg' }
                        ] } />
                    </PanelBody>
                </InspectorControls>
                ,
                <button className={ "btn " + type + ' ' + size + (classes ? ' ' + classes : '') }>
                    <RichText 
                        key="editable" 
                        tagName="span"
                        placeholder="Text" 
                        value={ text }
                        onChange={ onTextChange } />
                </button>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                text,
                type,
                size,
                classes
            } = attributes;

            return (
                <button className={ "btn " + type + ' ' + size + (classes ? ' ' + classes : '') } href={ link }>
                    { text }
                </button>
            );
        },
    });
}